<template>
    <div class="loyalty-banner mb-4">
        <h2 class="mt-0">
            {{ translate('loyalty.saveCoins') }}
        </h2>
        <div class="loyalty-image">
            <UIImage
                :lazy="false"
                class="border"
                :src="`images/loyalty/banner/page-asset-dundle.png`"
                :resource="true"
                alt="Dundle World"
                :responsive-widths="[280, 768, 1024, 1440]"
                :ratio="'220:311'"
                :height="220"
                sizes="(max-width: 768px) 100vw, (max-width: 960px) 33vw, 311px"
            />
            <UIButton variant="loyalty" @click="navigateToLoyalty">
                {{ translate('loyalty.returnPageButton') }}
            </UIButton>
        </div>
    </div>
</template>

<script>
import { UIButton, UIImage } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        UIButton,
        UIImage,
    },

    mixins: [TranslationMixin],

    methods: {
        navigateToLoyalty() {
            this.$router.navigate(this.$locale.path('reward-info'));
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

.loyalty-image {
    position: relative;
    height: 220px;
    margin: 0 auto;
    aspect-ratio: math.div(311, 220);

    img {
        border-radius: $border-radius-default;
        height: 220px;
    }

    button {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        width: calc(100% - 2rem);

        &::before {
            content: none !important;
        }
    }

    @media screen and (min-width: 1024px) {
        height: 220px;
    }
}
</style>

<i18n src="@dundle/locale/data/loyalty.json"></i18n>
